.swiper-button-prev,
.swiper-button-next {
	top: 140px;
	color: var(--color-primary);
	background-color: var(--color-primaryDark);
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
}
.swiper-button-prev {
	left: 0px;
}
.swiper-button-next {
	right: 0px;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
	background-color: var(--color-light-white);
	color: var(--color-primaryDark);
}
.swiper-button-prev::after,
.swiper-button-next::after {
	font-size: 20px !important;
}
#who_we_serve .swiper-button-prev,
.swiper-button-next {
	top: 90px !important;
}
.swiper-pagination-bullet-active {
	background-color: var(--color-primaryDark);
}
.swiper-slide {
	width: auto;
}
