@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Inter", sans-serif;
	font-family: "Montserrat", sans-serif;
}

:root {
	--color-primary: #00ffff;
	--color-primaryDark: #000f30;
	--color-light-white: #f6f4f4;
	--color-secondary: #555555;
}
